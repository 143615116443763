import React from 'react'

import Gallery from '../gallery'
import Modal from '../modal'

export default class GalleryModal extends React.Component {
  constructor(props) {
    super(props);
  }

  openModal = () => {
    if( this.modal ){
      this.modal.openModal();
    }
  }

  render() {
    return (
      <Modal ref={ ref => this.modal = ref }>
        <div className="react-tlc gallery-modal">
          {!!this.props.galleryName && (
            <div className="gallery-modal-header">
              <div className="gallery-modal-header-name">{this.props.galleryName}</div>
              <div className="gallery-modal-header-location">{this.props.galleryLocation}</div>
            </div>
          )}
          <Gallery
            galleryUrl={this.props.galleryUrl}
            imageIds={this.props.imageIds}
            activeImageId={this.props.activeImageId}
            description={this.props.galleryDescription}
          ></Gallery>
          
          <div className="gallery-modal-footer">
            {!!this.props.galleryDescription && (
              <div className="gallery-modal-footer-description">{this.props.galleryDescription}</div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}