import React from 'react'
import { copyTextToClipboard } from '../../shared/copy_text';
import { scrollToHorizontal } from '../../shared/scroll_to';

export default class Gallery extends React.Component {

  constructor(props) {
    super(props);
    const imageIds = this.props.imageIds.split(",");
    this.state = {
      galleryUrl: this.props.galleryUrl,
      imageIds,
      activeId: this.props.activeImageId || imageIds[0],
      canScrollLeft: false,
      canScrollRight: false,
      showShareWidget: false,
      coverOrContain: imageIds.map( () => 'cover' ),
      images: imageIds.map( () => new Image() ),
     };
  }


  componentDidMount() {
    this.state.imageIds.map( (id, index) => this.createPreloader(id, index, 0) );

    if( !!this.thumbnailRow ){
      this.thumbnailRow.addEventListener('scroll', this.updateCanScrolls);
      if( this.thumbnailRow.scrollWidth > this.thumbnailRow.offsetWidth ){
        this.setState({ canScrollRight: true });
      }
    }
  }

  componentWillUnmount() {
    if( !!this.thumbnailRow ) this.thumbnailRow.removeEventListener('scroll', this.updateCanScrolls);
  }

  componentDidUpdate(prevProps, prevState){
    if( prevState.activeId !== this.state.activeId ){ 
      this.scrollToActiveThumbnail();
      const activeIndex = this.state.imageIds.findIndex( (row) => { return row === this.state.activeId; } )
      this.state.imageIds.map( (id, index) => this.createPreloader(id, index, activeIndex) );
    }
  }

  updateCanScrolls = (e) => {
    const target = e.target;
    if( !!target ){
      this.setState({ 
        canScrollLeft: target.scrollLeft > 0,
        canScrollRight: target.scrollLeft !== (target.scrollWidth - target.offsetWidth) ,
       });
    }
  }

  renderThumbnail(id){
    const thumbnailImageUrl = `/api/v1/image_variants/${id}/thumbnail`;
    const classNames = ['gallery-thumbnail', this.state.activeId === id ? 'active' : ''].join(" ");

    return (
      <div 
        key={`thumbnail-${id}`}
        className={classNames} 
        style={{backgroundImage: `url(${thumbnailImageUrl})`}}
        onClick={() => this.setState({activeId: id})}
      ></div>
    );
  }

  createPreloader(id, index, activeIndex){
    const imageUrl = `/api/v1/image_variants/${id}/large`;
    const shouldPreloadImage = (index === activeIndex) || (index + 1 === activeIndex) || (index - 1 === activeIndex) || (index === 0 && activeIndex === this.state.imageIds.length - 1) || (index === this.state.imageIds.length - 1 && activeIndex === 0);

    if( shouldPreloadImage && !this.state.images[index].src ){
      this.state.images[index].src = imageUrl;
      this.state.images[index].onload = () => {
        this.setState((state, props) => {
          state.coverOrContain[index] = this.coverOrContain( state.images[index] );
          return state;
        })
      }
    }
  }

  scrollToActiveThumbnail = () => {
    if( !!this.thumbnailRow ){
      const activeThumbnail = this.thumbnailRow.querySelector(".active");
      this.scrollThumbnailContainer( activeThumbnail.offsetLeft - (this.thumbnailRow.offsetWidth/2) )
    }
  }

  scrollThumbnailContainer = (amt) => {
    if( !!this.thumbnailRow ){
      scrollToHorizontal(this.thumbnailRow, amt, 600);
    }
  }

  pageLeft = () => {
    const activeIndex = this.state.imageIds.findIndex( (row) => { return row === this.state.activeId; } )
    if( activeIndex > 0 ){
      this.setState({ activeId: this.state.imageIds[activeIndex - 1] });
    }else{
      this.setState({ activeId: this.state.imageIds[this.state.imageIds.length - 1] });
    }
  }

  pageRight = () => {
    const activeIndex = this.state.imageIds.findIndex( (row) => { return row === this.state.activeId; } )
    if( activeIndex < this.state.imageIds.length - 1 ){
      this.setState({ activeId: this.state.imageIds[activeIndex + 1] });
    }else{
      this.setState({ activeId: this.state.imageIds[0] });
    }
  }

  pinterestShare = () => {
    const currentUrl = encodeURI( `${this.state.galleryUrl}/image/${this.state.activeId}` );
    const featuredImageUrl = encodeURI(`https://buildbook.co/api/v1/image_variants/${this.state.activeId}/large`);
    window.open(`http://pinterest.com/pin/create/button/?url=${currentUrl}&media=${featuredImageUrl}`, "pop", "width=600, height=400, scrollbars=no");
  }

  facebookShare = () => {
    const currentUrl = encodeURI( `${this.state.galleryUrl}/image/${this.state.activeId}` );
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`, "pop", "width=600, height=400, scrollbars=no");
  }

  copyLink = () => {
    copyTextToClipboard(`${this.state.galleryUrl}/image/${this.state.activeId}`)
  }

  coverOrContain = (img) => {
    return img.height <= img.width ? 'cover' : 'contain';
  }

  render() {
    const activeIndex = this.state.imageIds.findIndex( (row) => { return row === this.state.activeId; } )
    const featuredImageUrl = `/api/v1/image_variants/${this.state.activeId}/large`;
    const backgroundSize = this.state.coverOrContain[activeIndex];

    return (
      <div className="react-tlc gallery">
        <div className="gallery-navigate-left" onClick={this.pageLeft}>
          <i className="fa fa-caret-left"></i>
        </div>
        <div className="gallery-container">
          <div className="gallery-share-widget">
            <div className="gallery-share-button"><i className="fal fa-share-alt"></i> Share</div>
            <div className="gallery-share-widget-button pinterest" onClick={() => this.pinterestShare()}><i className="fab fa-fw fa-pinterest"></i><span>Pinterest</span></div>
            <div className="gallery-share-widget-button facebook" onClick={() => this.facebookShare()}><i className="fab fa-fw fa-facebook"></i><span>Facebook</span></div>
            <div className="gallery-share-widget-button copylink" onClick={() => this.copyLink()}><i className="fal fa-fw fa-copy"></i><span>Copy Link</span></div>
          </div>
          
          <div className="gallery-featured-image" style={{backgroundImage: `url(${featuredImageUrl})`, backgroundSize }}>
            <div className="gallery-featured-image-nav-left" onClick={this.pageLeft}></div>
            <div className="gallery-featured-image-nav-right" onClick={this.pageRight}></div>
          </div>
          <div className="gallery-thumbnails-container">
            {this.state.canScrollLeft && (
              <div className="gallery-thumbnails-scroll-left" onMouseDown={this.pageLeft}>
                <i className="fa fa-caret-left"></i>
              </div>
            )}
            <div className="gallery-thumbnails" ref={ ref => this.thumbnailRow = ref }>
              {this.state.imageIds.map( id => this.renderThumbnail(id) )}
            </div>
            {this.state.canScrollRight && (
              <div className="gallery-thumbnails-scroll-right" onMouseDown={this.pageRight}>
                <i className="fa fa-caret-right"></i>
              </div>
            )}
          </div>
        </div>
        <div className="gallery-navigate-right" onClick={this.pageRight}>
          <i className="fa fa-caret-right"></i>
        </div>
      </div>
    );
  }
}