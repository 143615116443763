export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    addSuccessFlash("Copy successful!")
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    addSuccessFlash("Copy successful!")
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export function addSuccessFlash(message){
  const body = document.getElementsByTagName("body")[0];

  let alert = document.createElement('div');
  let alertContainer = document.createElement('div');
  alert.className='alert alert-success alert-dismissable fade hide';
  alertContainer.className='container'
  alertContainer.innerText = message;
  alert.appendChild(alertContainer);
  
  body.insertBefore(alert, body.firstChild);

  setTimeout(() => {
    const el = document.getElementsByClassName('alert hide')[0]
    el.classList.remove("hide");
    el.classList.add("show");

    setTimeout(() => {
      const el = document.getElementsByClassName('alert show')[0]
      el.classList.add("hide");
      el.classList.remove("show");
      setTimeout(() => { el.remove() }, 1010)
    }, 2000);
  }, 10);
  
}