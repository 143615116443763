import React from 'react'

import styled from 'styled-components'

const ModalContainer = styled.div`
  position: fixed !important;
  z-index: 1000000 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(54,54,54,0.95);
  overflow-x: hidden;
  overflow-y: auto;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const ModalClose = styled.div`
  position: fixed !important;
  z-index: 100000 !important;
  top: 30px;
  right: 40px;
  cursor: pointer;
  color: rgba(255,255,255, 0.85);

  @media (max-width : 768px) {
    top: 20px; 
    right: 25px;
  }
`

export default class Modal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  componentDidUpdate(prevProps) {
    document.addEventListener('keydown', this.checkForEscape);

    if( prevProps.modalOpen != this.props.modalOpen ){
      this.setState({ modalOpen: this.props.modalOpen });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.checkForEscape);
    
    if( this.state.modalOpen ){
      document.getElementsByTagName("body")[0].classList.remove("no-scroll");
    }
  }

  openModal = () => {
    this.setState({ modalOpen: true }, () => {
      document.getElementsByTagName("body")[0].classList.add("no-scroll");
    });
  }

  closeModal = () => {
    this.setState({ modalOpen: false }, () => {
      document.getElementsByTagName("body")[0].classList.remove("no-scroll");
    });
  }

  checkForEscape = (e) => {
    const event = e || window.event;
    if ( ("key" in event) && (event.key === "Escape" || event.key === "Esc") || (event.keyCode === 27) ) {
      this.closeModal();
    }
  }

  render() {
    if( !this.state.modalOpen ) return '';

    return (
      <ModalContainer>
        {!this.props.hideCloseButton && (
          <ModalClose onClick={this.closeModal}>
            <i className="fal fa-times-circle"></i> Close
          </ModalClose>
        )}
        {this.props.children}
      </ModalContainer>
    );
  }
}